import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

function Root() {
  return (
    <MainWrapper>
      <Outlet />
    </MainWrapper>
  );
}

const MainWrapper = styled.main`
  margin: 0 auto;
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
`;

export default Root;
