import React from "react";
import { styled } from "styled-components";
import { useSetRecoilState } from 'recoil';
import { messageModalInfo, profileModalInfo } from '../atoms/atoms';
import { useRecoilValue } from 'recoil';
import { joinInfo } from '../atoms/atoms';
import useSWR from 'swr';
import { get } from '../utils/fetcher';
import Avatar from '../components/Avatar';

export const ActivitiesRoom = () => {
  const {
    workspaceUrl,
    userId,
  } = useRecoilValue(joinInfo);
  const setProfileModalInfo = useSetRecoilState(profileModalInfo);
  const setMessageModal = useSetRecoilState(messageModalInfo);
  const {
    data : activeUserList,
    error: activeUserError,
  } = useSWR(`/api/workspaces/${workspaceUrl}/users`, get, { refreshInterval: 3000 });


  const onRowClick = ({id, nickname, userImg, isActive}) => {
    console.log(`isActive=${isActive}`);
    if(userId === id) {
      setProfileModalInfo({
        show: true,
      })
    } else if(isActive) {
      setMessageModal({
        show: true,
        pid: id,
        name: nickname,
        userImg: userImg,
      });
    }
  }

  const isActiveUser = (socketId) => {
    return socketId ? true: false;
  }

  const isNotActiveUser = (socketId) => {
    return socketId ? false: true;
  }

  if(activeUserError) {
    return (
      <ActivitiesRoomWrapper>
        We're currently undergoing maintenance. Please check back soon!
      </ActivitiesRoomWrapper>
    );
  } else {
    return (
      <ActivitiesRoomWrapper>
        <ul>
          { activeUserList ? (
              activeUserList?.map((row, i) => (
                <li key={i} onClick={()=> onRowClick({
                    id: row?.id,
                    nickname: row?.nickname,
                    userImg: row?.userImg,
                    isActive: isActiveUser(row?.socketId)
                  })}>
                  <Avatar nickname={row?.nickname} userImg={row?.userImg} isActive={isActiveUser(row?.socketId)} isFilter={isNotActiveUser(row?.socketId)}/>
                  <ActivitiesRoomName>
                    {row?.nickname}
                  </ActivitiesRoomName>
                </li>
              ))
            ) : (
              <></>
            )
          }
        </ul>
      </ActivitiesRoomWrapper>
    );
  }
};

const ActivitiesRoomWrapper = styled.ul`
  position: relative;
  margin: 0 auto;
  padding: 3rem 0rem;
  height: inherit;
  width: 100%;
  overflow: auto;
  overflow-x: auto;
  overflow-y: auto;
  & ul {
    margin: 0 auto;
    width: 32rem;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    gap: 2rem;
    & li {
      position: relative;
      width: 6.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem 2rem;
      cursor: pointer;
    }
  }
`;

const ActivitiesRoomName = styled.div`
  position: relative;
  width: 100%;
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.463rem;
  text-align: center;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
