import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { joinInfo } from '../atoms/atoms';
import { get, post } from '../utils/fetcher';
import useSWR from 'swr';
import axios from "axios";

export const Join = () => {
  const {
    data: userData, error: userError
  } = useSWR(`/api/users`, get, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const cname = queryParams.get("cname");
  const navigate = useNavigate();
  const [joinInfoData, setJoinInfoData] = useRecoilState(joinInfo);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: {
      workspaceUrl: cname ? cname : joinInfoData.workspaceUrl,
    }
  });

  const onValid = (data) => {
    const sendData = {
      "nickname" : `${data?.workspaceUrl?.trim()}Ð${data?.nickname?.trim()}`,
      "password": data?.password,
      "workspaceUrl": data?.workspaceUrl?.trim()
    }
    axios.post(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PORT}/api/users/login`,
      sendData,
      { withCredentials: true }
    ).then((response) => {
      const { data: resData } = response;

      setJoinInfoData({
        workspaceUrl: data?.workspaceUrl,
        ipAddress: data?.ipAddress,
        nickname: resData?.nickname,
        userId: resData?.id,
      });

      navigate('/main');
    }).catch((error) => {
      const { response } = error;
      if(response) {
        if(401 === response?.status) {
          alert("Invalid login information. Please check your nickname and password, and try again.");
        } else if(403 === response?.status) {
          alert("Please access via QR code with allowed wifi.");
        } else if(404 === response?.status) {
          alert("Please access via QR code with allowed wifi.");
        } else {
          console.log(response);
          alert(response?.statusText);
        }
      } else {
        console.log(error);
        alert("We're currently undergoing maintenance. Please check back soon!");
      }
    })
  };

  // == state 변경 ------------------------------------------------
  useEffect(() => {

    if (!userError && userData) {
      // navigate('/main');
    }
    if(userError) {
      const { response } = userError;
      if(response) {
        if(403 === response?.status) {
          post(`/api/users/logout`
          ).then((res) => {
            console.log(res);
          }).catch((error) => {
            console.log(error);
          });
          // alert("Please access via QR code with allowed wifi.");
        } else if(404 === response?.status) {
          // alert("Please access via QR code with allowed wifi.");
        }
      } else {
        console.log(userError);
      }
    }
  }, [navigate, userData, userError]);

  useEffect(() => {
    const fetchIp = async () => {
      // try {
      //   const response = await fetch('https://api.ipify.org?format=json');
      //   const data = await response.json();
      //   setValue("ipAddress", data?.ip);
      // } catch (error) {
      //   setValue("ipAddress", "127.0.0.1");
      // }
      setValue("ipAddress", "127.0.0.1");
    };
    fetchIp();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <JoinWrapper>
      <LogoWrapper>
        <img src='/img/torderchat-join-logo.png' alt='T-Order Chat Logo' />
      </LogoWrapper>
      <WelcomeWrapper>
        <div>Share the Moment</div>
        <div>Meet the Table Next Door!</div>
      </WelcomeWrapper>
      <FormWrapper onSubmit={handleSubmit(onValid)}>
        <InputWrapper>
          <div>
            <input
              {...register("nickname", { required: 'Nick name is required!' })}
              placeholder="Nickname"
            />
          </div>
          <span>{errors?.nickname?.message}</span>
        </InputWrapper>
        <InputWrapper>
          <div>
            <input type='password'
              {...register("password", { required: 'password is required!',
                minLength: {
                  value: 6,
                  message: "Must have at least 6 characters",
                },
              })}
              placeholder="Password of 6 characters or more"
            />
          </div>
          <span>{errors?.password?.message}</span>
        </InputWrapper>
        <InputWrapper>
          <div style={{display: 'none'}}>
            <input
              {...register("workspaceUrl", { required: 'Workspace name is required!' })}
              placeholder="Workspace name"
            />
          </div>
        </InputWrapper>
        <InputWrapper>
          <div style={{display: 'none'}}>
            <input
              {...register("ipAddress", { required: 'IP Address is required!' })}
              placeholder="IP Address"
            />
          </div>
        </InputWrapper>
        <JoinButton type='submit'>JOIN</JoinButton>
        <BottomWrapper>
          Connect Across the Table, Enjoy!
        </BottomWrapper>
      </FormWrapper>
    </JoinWrapper>
  );
};

const JoinWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
  min-width: 32.0rem;
  max-width: 43.0rem;
  height: 90.6rem;
  background-color: #E5392C;
  background-image: url('/img/join-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  border: 0rem solid #dadada;

`;

const LogoWrapper = styled.div`
  padding-top: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    width: 14.2rem;
    height: 14.8rem;
  }
`;

const WelcomeWrapper = styled.div`
  margin-top: 3rem;
  font-family: Poppins;
  color: #fff;
  & div:nth-child(1) {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
  }
  & div:nth-child(2) {
    font-size: 15px;
    font-weight: 400;
    text-align: center;
  }
`;

const FormWrapper = styled.form`
  padding: 4.4rem 7.6rem 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.3rem;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  & div {
    padding: 0.8rem 0rem 0.7rem;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 0rem solid #a1a1a1;
    border-radius: 0.8rem;

    color: ${(props) => props.theme.textColor};
    background-color: ${(props) => props.theme.bgColor};
    & input {
      padding-left: 2.5rem;
      width: 100%;
      height: 3.4rem;
      border: 0;
      outline: none;
    }
  }
  & span {
    display: inline-block;
    padding-top: 0.3rem;
    padding-left: 0;
    color: #fff;
  }
`;

const JoinButton = styled.button`
  position: absolute;
  bottom: 8rem;
  width: 13.1rem;
  height: 5.6rem;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #E5392C;
  border: 1px solid #E5392C;
  border-radius: 1.2rem;
  /* box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); */
`;

const BottomWrapper = styled.div`
  position: absolute;
  bottom: 4rem;
  font-family: Poppins;
  color: #6a6565;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;
