import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRecoilValue, useRecoilState } from 'recoil';
import { joinInfo, profileModalInfo } from '../atoms/atoms';
import axios from 'axios';

const ProfileModal = () => {
  const joinInfoData = useRecoilValue(joinInfo);
  const [profileModal, setProfileModal] = useRecoilState(profileModalInfo);

  const handleModalClose = () => {
    setProfileModal(false);
  }

  const handleMainClick = (e) => {
    e.stopPropagation();
  }

  const handleImgClick = (imgUrl) => {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/users/img`,
        { userImg: imgUrl, },
        { withCredentials: true }
      )
      .then((res) => {
        handleModalClose();
      })
      .catch(
        console.error
    ) ;
  }

  useEffect(() => {
    // if(profileModal) {
    //   document.body.style.cssText = `
    //   position: fixed;
    //   top: -${window.scrollY}px;
    //   overflow-y: scroll;
    //   width: 100%;`;
    // }

    return () => {
      // const scrollY = document.body.style.top;
      // document.body.style.cssText = '';
      // window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, [profileModal]);

  return (
    <>
      {profileModal.show && (
        <Modal onClick={handleModalClose}>
          <ModalBody onClick={handleMainClick}>
            <Header>
              <img src='/img/edit_profile_modal_logo.png' alt='Edit Profile Modal'/>
              <div>{`${joinInfoData.nickname}'s Profile`}</div>
            </Header>
            <Body>
              <ul>
                <li><img src='/img/avatar06.png' alt='' onClick={()=>handleImgClick('/img/avatar06.png')}/></li>
                <li><img src='/img/avatar03.png' alt='' onClick={()=>handleImgClick('/img/avatar03.png')}/></li>
                <li><img src='/img/avatar04.png' alt='' onClick={()=>handleImgClick('/img/avatar04.png')}/></li>
                <li><img src='/img/avatar01.png' alt='' onClick={()=>handleImgClick('/img/avatar01.png')}/></li>
                <li><img src='/img/avatar05.png' alt='' onClick={()=>handleImgClick('/img/avatar05.png')}/></li>
                <li><img src='/img/avatar02.png' alt='' onClick={()=>handleImgClick('/img/avatar02.png')}/></li>
              </ul>
            </Body>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default ProfileModal;

const Modal = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalBody = styled.div`
  padding: 0;
  position: fixed;
  left: 50%;
  bottom: 0;

  width: 100vw;
  min-width: 32.0rem;
  max-width: 43.0rem;
  height: 50vh;

  background-color: #FEF8F8;
  border-top: 0.8px solid #FF1E1E;
  border-radius: 19.5rem 19.5rem 0 0;

  transform: translate(-50%, 0);
`;

const Header = styled.div`
  padding: 0;
  height: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  & img {
    width: 8.8rem;
    height: 10.1rem;
  }
  & div {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: center;
  }
`;

const Body = styled.div`
  margin: 0 auto;
  padding: 0 3rem;
  width: 100%;
  max-width: 41.4rem;
  height: calc(54vh - 17rem);
  overflow: hidden;
  overflow-y: auto;
  & ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    & li {
      width: 7rem;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
