import React from 'react';
import { styled } from 'styled-components';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { joinInfo, messageModalInfo } from '../atoms/atoms';
import Avatar from '../components/Avatar';
import NewIcon from '../components/NewIcon';
import { timeForToday } from '../utils/timeUtils'
import { get } from '../utils/fetcher';
import useSWR from 'swr';

export const PersonalRoom = () => {
  const joinInfoData = useRecoilValue(joinInfo);
  const { workspaceUrl } = joinInfoData;
  const setMessageModal = useSetRecoilState(messageModalInfo);
  const {
    data : userDmsList,
    error: userDmsError,
  } = useSWR(`/api/workspaces/${workspaceUrl}/dms`, get, { refreshInterval: 3000 });

  const onRowClick = (id, nickname, userImg) => {
    setMessageModal({
      show: true,
      pid: id,
      name: nickname,
      userImg: userImg,
    });
  }

  const isActiveUser = (socketId) => {
    return socketId ? true: false;
  }

  if(userDmsError) {
    return (
      <PersonalRoomMessagesWrapper>
        We're currently undergoing maintenance. Please check back soon!
      </PersonalRoomMessagesWrapper>
    );
  } else {
    return (
      <PersonalRoomMessagesWrapper>
        { userDmsList ?
          (
            userDmsList.map((row, i) => (
              <li key={i} onClick={()=> onRowClick(row.contactUserId, row.contactUserNickname, row.contactUserImg)}>
                <Avatar nickname={row.contactUserNickname} userImg={row.contactUserImg} isActive={isActiveUser(row?.contactSocketId)}/>
                <PersonalRoomInfo>
                  <PersonalRoomName>
                    <div>{row.contactUserNickname}</div>
                    <span>{`${timeForToday(row.updatedAt)}`}</span>
                  </PersonalRoomName>
                  <Message>
                    {row.latestContent}
                  </Message>
                  { row.tag === 'new' && (
                    <NewIconWrapper>
                      <NewIcon size={2.2} />
                    </NewIconWrapper>
                  )}
                </PersonalRoomInfo>
              </li>
            ))
          ) : (
            <></>
          )
        }
      </PersonalRoomMessagesWrapper>
    );
  }
};

const PersonalRoomMessagesWrapper = styled.ul`
    position: relative;
    padding: 0rem 0rem;
    height: inherit;
    overflow: auto;
    & li {
      position: relative;
      margin: 1rem 0;
      padding-left: 2.2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
      border-bottom: 0.1rem solid #dcdcdc;
      cursor: pointer;
    }
`;

const NewIconWrapper = styled.span`
  position: absolute;
  top: 2.3rem;
  right: 1.9rem;
`;

const PersonalRoomInfo = styled.div`
  position: relative;
  padding: 0 2rem 0 0;
  width: 75%;
  border-bottom: 0.0rem solid #edecef;
`;

const PersonalRoomName = styled.div`
  position: relative;
  padding-top: 1.2rem;
  display: flex;
  justify-content: space-between;

  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: 500;
  color: #000;
  & span {
    font-size: 1rem;
    color: ${(props) => props.theme.textColor};
    opacity: 0.4;
  }
`;

const Message = styled.div`
  position:relative;
  padding: 1.4rem 0rem 1.8rem;
  width: 95%;

  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: 500;
  color: #b2afaf;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;