import { useCallback } from 'react';
import { io } from 'socket.io-client';

const backUrl = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_SOCKET_PORT}`;

const sockets = {};
const useSocket = (workspaceUrl) => {
  const disconnect = useCallback(() => {
    if (workspaceUrl && sockets[workspaceUrl]) {
      console.log('소켓 연결 끊음');
      sockets[workspaceUrl].disconnect();
      delete sockets[workspaceUrl];
    }
  }, [workspaceUrl]);
  if (!workspaceUrl) {
    return [undefined, disconnect];
  }
  if (!sockets[workspaceUrl]) {
    // sockets[workspace] = io(`${backUrl}/ws-${workspace}`, {
    sockets[workspaceUrl] = io(`${backUrl}`, {
      transports: ['websocket'],
      reconnection: true, // 재연결 시도 활성화
      reconnectionAttempts: 5, // 최대 재연결 시도 횟수
      reconnectionDelay: 10000, // defaults to 1000
      reconnectionDelayMax: 10000, // defaults to 5000
    });
    console.info('create socket', workspaceUrl, sockets[workspaceUrl]);
    sockets[workspaceUrl].on('connect_error', (err) => {
      console.error(err);
      console.log(`connect_error due to ${err.message}`);
    });
  }

  return [sockets[workspaceUrl], disconnect];
};

export default useSocket;
