import Dexie from 'dexie';

export const db = new Dexie('torderchat');
db.version(1).stores({
    activeUsers: 'sid, name',
    messages: '++id, type, pid, fromSid, fromName, toSid, toName, message, date',
    personalRoom: 'pid, sid, name, message, tag, date',
    dms: 'userId, contactUserId, latestContent, updatedAt, contactUserNickname, contactUserImg, contactSocketId'
});

export function resetDatabase() {
  db.activeUsers.clear();
  db.messages.clear();
  db.personalRoom.clear();
  db.dms.clear();
}
