import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PORT}`,
  timeout: 5000,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // getToken() - 클라이언트에 저장되어 있는 액세스 토큰을 가져오는 함수
    // const accessToken = getToken();
    // config.headers['Content-Type'] = 'application/json';
    // config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 404) {
      console.log('404 페이지로 넘어가야 함!');
    }
    return response;
  },
  async (error) => {
    // if (error.response?.status === 401) {
      // isTokenExpired() - 토큰 만료 여부를 확인하는 함수
      // tokenRefresh() - 토큰을 갱신해주는 함수
      // if (isTokenExpired()) await tokenRefresh();

      // const accessToken = getToken();

      // error.config.headers = {
      //   'Content-Type': 'application/json',
      //   Authorization: `Bearer ${accessToken}`,
      // };

      // 중단된 요청을(에러난 요청)을 토큰 갱신 후 재요청
      // const response = await axios.request(error.config);
      // return response;
    // }
    if(error?.response?.status === 403) {
      console.log("403 error~");
      // window.location.href = '/';

    }
    return Promise.reject(error);
  }
);

export const get = async (url) => {
  return await axiosInstance.get(url).then((res) => res.data);
}

export const post = async (url, body) => {
  return await axiosInstance.post(url, body).then((res) => res.data);
}
