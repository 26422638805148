import { Link, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { UrlPaths } from '../atoms/atoms';
import NewIcon from './NewIcon';

const NavigationTopBar = ({personalUnreadCount}) => {
  const location = useLocation();

  const isActive = (path) => {
    const { pathname } = location;
    return pathname.endsWith(path);
  }
  return (
    <NavWrapper>
      <ul>
        <NavButton $actives={isActive(UrlPaths.main)}>
          <Link to={"/main"}>
          {isActive(UrlPaths.main) ? (
            <div style={{color: "#000",}}>Open chat</div>
          ) : (
            <div style={{color: "#adafbb",}}>Open chat</div>
          )}
          </Link>
        </NavButton>
        <NavButton $actives={isActive(UrlPaths.activities)}>
          <Link to={"/main/activities"}>
            {isActive(UrlPaths.activities) ? (
              <div style={{color: "#000",}}>Activities</div>
            ) : (
              <div style={{color: "#adafbb",}}>Activities</div>
            )}
          </Link>
        </NavButton>
        <NavButton $actives={isActive(UrlPaths.personal)}>
          <Link to={"/main/personal"}>
            {isActive(UrlPaths.personal) ? (
              <div style={{color: "#000",}}>Personal</div>
            ) : (
              <div style={{color: "#adafbb",}}>Personal</div>
            )}
          </Link>
          {personalUnreadCount > 0 && (
            <NewIconWrapper>
              <NewIcon size={1.3}/>
            </NewIconWrapper>
          )}
        </NavButton>
      </ul>
    </NavWrapper>
  );
}

const NavWrapper = styled.section`
  position: relative;
  padding: 1.5rem 0;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  color: 7F7D7D;
  border-bottom: 0.1rem solid #DCDCDC;

  & ul {
    list-style: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
`;

const NavButton = styled.li`
  position: relative;
  width: 7rem;
  height: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  & div {
    cursor: pointer;
  }
`;

const NewIconWrapper = styled.span`
  position: absolute;
  top: 0.4rem;
  right: -0.9rem;
`;

export default NavigationTopBar;