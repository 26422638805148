import { useSetRecoilState, useRecoilValue } from 'recoil';
import { styled } from "styled-components";
import { joinInfo, profileModalInfo, messageModalInfo } from '../atoms/atoms';
import Avatar from "./Avatar";

const ActiveUser = ({ nickname, sid, userImg }) => {
  const joinInfoData = useRecoilValue(joinInfo);
  const setProfileModalInfo = useSetRecoilState(profileModalInfo);
  const setMessageModal = useSetRecoilState(messageModalInfo);

  const onAvatarClick = (id, nickname) => {
    if(joinInfoData.userId === id) {
      setProfileModalInfo({
        show: true,
      })
    } else {
      setMessageModal({
        show: true,
        pid: sid,
        name: nickname,
        userImg: userImg
      });
    }
  }

  return (
    <UserWrapper >
      <div onClick={()=>onAvatarClick(sid, nickname)} >
        <Avatar nickname={nickname} userImg={userImg}/>
      </div>
      <Name>{nickname}</Name>
    </UserWrapper>
  );
};

const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0px solid #000;
  color: ${(props) => props.theme.textColor};
`;

const Name = styled.div`
  color: ${(props) => props.theme.textColor};
  text-align: center;
`;

export default ActiveUser;
