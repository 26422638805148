import { useRef, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { styled } from 'styled-components';
import { useRecoilValue } from 'recoil';
import { joinInfo } from '../atoms/atoms';
import ActiveUser from './ActiveUser';

const MainRoomMessages = () => {
  const messageEndRef = useRef(null);

  const joinInfoData = useRecoilValue(joinInfo);
  const { chatList } = useOutletContext();

  const isYourMessage = (senderId) => {
    return senderId === joinInfoData.userId;
  }

  const isNotYourMessage = (senderId) => {
    return !isYourMessage(senderId);
  }

  useEffect(() => {
    messageEndRef.current.scrollIntoView();
  }, [chatList]);

  return (
    <MainRoomMessagesWrapper>
      <div>
        <ul>
          {chatList && (
            chatList.map((row, i) => {
              const { type, nickname, senderId, userImg } = row;
              switch(type) {
                case 'welcome':
                  return (
                    <WelcomeMessageRow key={i}>
                      <div>
                        {`${nickname} has just entered the chat`}
                      </div>
                    </WelcomeMessageRow>
                  );
                default:
                  return (
                    <MessageRow key={i} $isRight={isYourMessage(senderId)}>
                      {isNotYourMessage(senderId) &&
                        <ActiveUser nickname={nickname} sid={senderId} userImg={userImg}/>
                      }
                      <Message $isRight={isYourMessage(senderId)} $isEven={i%2}>
                        {row.content}
                      </Message>
                      {isYourMessage(senderId) &&
                        <ActiveUser nickname={nickname} sid={senderId} userImg={userImg}/>
                      }
                    </MessageRow>
                  );
              }
            }
          ))}
        </ul>
        <div ref={messageEndRef}></div>
      </div>
    </MainRoomMessagesWrapper>
  );
};

const MainRoomMessagesWrapper = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0 2.2rem;

  color: ${(props) => props.theme.textColor};

  &>div {
    padding-top: 2.4rem;
    position: relative;
    & ul {
      padding: 0rem 0rem 1rem;
    }
  }
`;

const MessageRow = styled.li`
  margin: 0;
  display: flex;
  justify-content: ${props => props.$isRight? `flex-end;`:`flex-start;` };
  align-items: center;
  gap: 2rem;
`;

const Message = styled.div`
  position: relative;
  padding: 1.8rem 3rem;

  font-size: 1.2rem;
  color: #000;
  word-break: break-all;
  border-radius: 1rem;
  background-color: ${props => props.$isRight? `#E9FFE9`: `#E9F2FF` };

  &:after {
    content:"";
    position: absolute;
    bottom: 1.1rem;
    ${props => props.$isRight? `right: -1rem;`: `left: -1rem;` };
    ${props => props.$isRight? (
      `border-left: 2rem solid #E9FFE9`
    ) : (
      `border-right: 2rem solid #E9F2FF`
    )};
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
  }
`;

const WelcomeMessageRow = styled.li`
  margin: 0;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  & div {
    padding: 0.3rem 1.1rem;
    font-size: 1.2rem;
    border: 0.1rem solid #f1f1f1;
    border-radius: 0.4rem;
    background-color: #f1f1f1;
  }
`;

export default MainRoomMessages;
