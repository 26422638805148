import styled from 'styled-components';

const Avatar = ({ userImg, nickname, isActive=false, isFilter=false}) => {
  return (
    <AvatarWrapper $isActive={isActive} $isFilter={isFilter}>
      {userImg
        ? <img src={`${userImg}`} alt={`${nickname}'s profile images.`} />
        : <img src='/img/avatar00.png' alt={`${nickname}'s profile images.`} />
      }
    </AvatarWrapper>
  );
};

const AvatarWrapper = styled.div`
  position: relative;
  width: 6.3rem;
  height: 6.3rem;

  ${props => props.$isActive && (
    `border: 3px solid transparent;
    border-radius: 50%;
    background-image: linear-gradient(#fff, #fff), linear-gradient(to right, #00ff18 0%, #bfff00 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;`
  )}

  & img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0.1rem solid #fff;
    ${props => props.$isFilter && (
      `filter: brightness(40%);`
    )}
  }
`;

export default Avatar;
