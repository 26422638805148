import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { joinInfo, messageModalInfo } from '../atoms/atoms';
import { db } from '../utils/db';
import Avatar from './Avatar';
import PersonalMessages from './PersonalMessages';
import { get, post } from '../utils/fetcher';
import useSWR from 'swr';

const MessageModal = ({worker, savePersonalMessageByEmit}) => {
  const joinInfoData = useRecoilValue(joinInfo);
  const [messageModal, setMessageModal] = useRecoilState(messageModalInfo);
  const { register, handleSubmit, setValue } = useForm();
  const {
    data : dms,
    error: dmsError,
    mutate: dmsMutate
  } = useSWR(messageModal?.pid ? `/api/workspaces/${joinInfoData.workspaceUrl}/dms/${messageModal?.pid}/chats` : null, get, { refreshInterval: 3000 });

  const changePersonalMessageTag = () => {
    try {
      db.personalRoom.update(messageModal?.pid, { tag: '' })
        .then(async (updated) => {
          if (updated)
            console.log("sss number 2 was renamed to Number 2");
          else {
            console.log("ss was updated - there were no friend with primary key: 2");
          }
        }
        );
    } catch (error) {
      // console.log(`Failed to add ${message}: ${error}`);
    }
  }

  const handleMessageModal = () => {
    changePersonalMessageTag();
    setMessageModal(!messageModal.show);
  }

  const handleMainClick = (e) => {
    e.stopPropagation();
  }

  const onValid = (data) => {
    post(`/api/workspaces/${joinInfoData.workspaceUrl}/dms/${messageModal?.pid}/chats`,
        { content: data.sendPersonalMessage, },
        { withCredentials: true }
      )
      .then((res) => {
        dmsMutate();
      })
      .catch(
        console.error
    ) ;
    setValue("sendPersonalMessage", '');
  }

  useEffect(() => {
    // if(messageModal) {
    //   document.body.style.cssText = `
    //   position: fixed;
    //   top: -${window.scrollY}px;
    //   overflow-y: scroll;
    //   width: 100%;`;
    // }

    return () => {
      // const scrollY = document.body.style.top;
      // document.body.style.cssText = '';
      // window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, [messageModal]);

  return (
    <>
      {messageModal.show && (
        <Modal onClick={handleMessageModal}>
          <ModalBody onClick={handleMainClick}>
            <Header>
              <Avatar nickname={messageModal?.name} userImg={messageModal?.userImg}/>
              <div>
                <Nickname>{messageModal?.name}</Nickname>
                {/* <OnlineState>Online</OnlineState> */}
              </div>
              <CloseBtn src='/img/x-mark.png' alt="close button" onClick={()=>handleMessageModal()} />
            </Header>
            <PersonalMessageWrapper>
              <PersonalMessages messages={dms}/>
            </PersonalMessageWrapper>
            <SendMessageWrapper onSubmit={handleSubmit(onValid)}>
              <img src='/img/btn_input_message.png' alt='input button' />
              <input
                {...register("sendPersonalMessage", { required: true})}
                placeholder="Write your message here"
              />
              <img src='/img/btn_send_message.png' alt='send button' onClick={handleSubmit(onValid)}/>
            </SendMessageWrapper>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default MessageModal;

const Modal = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalBody = styled.div`
  padding: 0;
  position: fixed;
  left: 50%;
  bottom: 0;

  width: 100vw;
  min-width: 32.0rem;
  max-width: 43.0rem;
  height: 70vh;

  background-color: rgb(255, 255, 255);
  background-image: url('/img/room_bg_logo.png');
  background-repeat: no-repeat;
  background-size: 22.9rem 26.1rem;
  background-position: center;

  border-radius: 3.6rem 3.6rem 0 0;

  transform: translate(-50%, 0);
`;

const Header = styled.div`
  padding: 1.8rem 0 0 1.4rem;
  height: 8rem;
  display: flex;
  align-items: center;
  & div:first-child {
    margin-left: 3rem;
  }
`;

const CloseBtn = styled.img`
  position: absolute;
  top: 2.3rem;
  right: 3rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
`;

const Nickname = styled.div`
  font-size: 1.6rem;
`;

const PersonalMessageWrapper = styled.div`
  height: calc(70vh - 14rem);
  overflow: hidden;
  overflow-y: auto;
`;

const SendMessageWrapper = styled.form`
  padding: 0 2rem;
  width: 100%;
  height: 7rem;

  position: fixed;
  left: 0rem;
  bottom: 0rem;

  display: flex;
  align-items: center;
  gap: 2rem;
  color: ${(props) => props.theme.textColor};

  & input {
    padding-left: 4.7rem;
    width: 100%;
    height: 4.5rem;
    font-size: 12px;
    font-weight: 400;
    color: #999;
    background-color: #f1f1f1;
    border: 0rem solid #e7e6ea;
    border-radius: 1.1rem;
    outline: none;
  }
  & img:first-child {
    position: absolute;
    top: 2.2rem;
    left: 3.5rem;
    width: 2.5rem;
  }
  & img:last-child {
    position: absolute;
    top: 2.2rem;
    right: 3.5rem;
    width: 2.5rem;
    cursor: pointer;

    transition-duration: 0.3s;
    &:active {
      transform: rotate(15deg);
    }
  }
`;