import { useRef, useEffect } from 'react';
import { styled } from 'styled-components';
import { useRecoilValue } from 'recoil';
import { joinInfo } from '../atoms/atoms';
import { timeForToday } from '../utils/timeUtils'

const PersonalMessages = ({messages}) => {
  const messageEndRef = useRef(null);
  const joinInfoData = useRecoilValue(joinInfo);

  const isYourMessage = (senderId) => {
    return senderId === joinInfoData.userId;
  }

  useEffect(() => {
    messageEndRef.current.scrollIntoView();
  }, [messages]);


  return (
    <MessagesWrapper>
      <div>
        <ul>
          {messages && (
            messages?.map((row, i) => {
              return (
                <MessageRow key={i} $isRight={isYourMessage(row.dms_senderId)}>
                  <Message $isRight={isYourMessage(row.dms_senderId)}>
                    {row.dms_content}
                    <ResDate>
                      {`${timeForToday(row.dms_updatedAt)}`}
                    </ResDate>
                  </Message>
                </MessageRow>
              );
            }
          ))}
        </ul>
        <div ref={messageEndRef}></div>
      </div>
    </MessagesWrapper>
  );
};

const MessagesWrapper = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0 2rem;

  color: ${(props) => props.theme.textColor};

  &>div {
    padding-top: 2.4rem;
    position: relative;
    & ul {
      padding: 0rem 0rem 1rem;
    }
  }
`;

const MessageRow = styled.li`
  position: relative;
  margin: 0;
  padding: 1.3rem 0;

  display: flex;
  justify-content: ${props => props.$isRight? `flex-end;`:`flex-start;` };
  align-items: center;
  gap: 2rem;
`;

const Message = styled.div`
  position: relative;
  padding: 2rem 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.2rem;
  color: #000;

  border-radius: ${props => props.$isRight? `2rem 2rem 0rem 2rem`: `2rem 2rem 2rem 0rem` };
  background-color: ${props => props.$isRight? `#E9FFE9`: `#E9F2FF` };
`;

const ResDate = styled.span`
  position: absolute;
  bottom: -1.7rem;
  right: 0.3rem;

  font-weight: 600;
  color: ${(props) => props.theme.textColor};
  opacity: 0.4;
`;

export default PersonalMessages;
