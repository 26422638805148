import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { createBrowserHistory } from "history";
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import useSocket from '../hooks/useSocket';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { joinInfo, profileModalInfo } from '../atoms/atoms';
import { get, post } from '../utils/fetcher';
import useSWR from 'swr';
import NavigationTopBar from '../components/NavigationTopBar';
import MessageModal from '../components/MessageModal';
import ProfileModal from '../components/ProfileModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';

export const MainRoom = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const history = createBrowserHistory();
  const joinInfoData = useRecoilValue(joinInfo);
  const setProfileModalInfo = useSetRecoilState(profileModalInfo);
  const { userId, workspaceUrl, ipAddress, nickname } = joinInfoData;
  const [ socket, disconnectSocket ] = useSocket(workspaceUrl);
  const { register, handleSubmit, setValue } = useForm();
  const {
    data : chatList,
    error: chatError,
    mutate: chatMutate
  } = useSWR(`/api/workspaces/${workspaceUrl}/chats`, get, {
    refreshInterval: 3000
  });
  const {
    data : personalUnreadCount,
    // error: personalUnreadCountError,
  } = useSWR(`/api/workspaces/${workspaceUrl}/dms/unreads`, get, {
    refreshInterval: 5000,
  });

  const isMain = () => {
    const { pathname } = location;
    return pathname.endsWith('main');
  }

  const onProfileEditClick = () => {
    setProfileModalInfo({
      show: true,
    })
  }

  const onValid = (data) => {
    post(`/api/workspaces/${workspaceUrl}/chats`,
      { content: data.sendMessage, }
    ).then((res) => {
      chatMutate();
    }).catch(
      console.error
    ) ;
    setValue("sendMessage", '');
  }

  const savePersonalMessageByOn = async ({type, fromName, fromSid, toName, toSid, message, date}) => {
    console.log(type, fromName, fromSid, toName, toSid, message, date);
  }

  const preventGoBack = () => {
    history.push(null, '', history.location.href);
    alert("Back navigation is not supported here.");
  }

  const onSignoutClick = (e) => {
    e.preventDefault();
    if(window.confirm('Are you sure you want to log out? Unsaved changes may be lost.')) {
      post(`/api/users/logout`
      ).then((res) => {
        console.log(res);
      }).catch((error) => {
        console.log(error);
      }).finally (()=>{
        navigate('/');
      });
    }
  }

  useEffect(() => {
    return () => {
      console.info('disconnect socket', workspaceUrl);
      disconnectSocket();
    };
  }, [disconnectSocket, workspaceUrl]);

  useEffect(() => {
    console.info(`로그인하자. userId: ${userId}, nickname: ${nickname}, workspaceUrl: ${workspaceUrl}, connected: ${socket.connected}`, socket);
    if(userId && nickname && workspaceUrl) {

      socket.on('connect', () => {
        console.log(`connect ${nickname}!`);
        console.log("recovered?", socket.recovered);
        socket?.emit('login', { userId, nickname, workspaceUrl});
      });
    }
    return () => {
      socket.disconnect();
      socket?.off('connect');
    };
  }, [nickname, socket, userId, workspaceUrl]);

  useEffect(() => {
    if(chatError) {
      const { response } = chatError;
      if(response) {
        if(403 === response?.status) {
          post(`/api/users/logout`
          ).then((res) => {
            console.log(res);
          }).catch((error) => {
            console.log(error);
          }).finally (()=>{
            navigate('/');
          });
        } else {
          console.log(response);
        }
      } else {
        console.log(chatError);
      }
    }
  }, [chatError, navigate]);

  useEffect(() => {
    // window.scrollTo(0,0);
    // document.body.scrollTop = 0;

    if( !workspaceUrl || !ipAddress || !nickname ) {
      post(`/api/users/logout`
      ).then((res) => {
        console.log(res);
      }).catch((error) => {
        console.log(error);
      }).finally (()=>{
        navigate('/');
      });
      return false;
    }

    (() => {
      history.push(null, '', history.location.href);
      window.addEventListener('popstate', preventGoBack);
    })();

    return () => {
      window.removeEventListener("popstate", preventGoBack);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainRoomWrapper>
        <MainHeader>
          <LogoutButton onClick={onSignoutClick}>
            {/* <img src='/img/chevron_left_thin.png' alt='signout' /> */}
            <FontAwesomeIcon icon={faArrowRightFromBracket} style={{color: '#797575', fontSize: '2.1rem'}} />
          </LogoutButton>
          <h2>
            Message & Chat
          </h2>
          <ProfileButton onClick={()=>onProfileEditClick()}>
            <img src='/img/btn_setup.png' alt='setup' />
          </ProfileButton>
        </MainHeader>
        <NavigationTopBar personalUnreadCount={personalUnreadCount} />
        {chatError? (
          <div>
            We're currently undergoing maintenance. Please check back soon!
          </div>
        ) : (
          <MainRoomComponent $isMain={isMain()}>
            <Outlet context={{chatList}}/>
          </MainRoomComponent>
        )}
      </MainRoomWrapper>
      {isMain() && (
        <SendMessageWrapper onSubmit={handleSubmit(onValid)}>
          {/* <img src='/img/btn_input_message.png' alt='input button' /> */}
          <input
            {...register("sendMessage", { required: true})}
            placeholder="Write your message here"
          />
          <img src='/img/btn_send_message.png' alt='send button' onClick={handleSubmit(onValid)}/>
        </SendMessageWrapper>
      )}
      <MessageModal
        savePersonalMessageByOn={savePersonalMessageByOn}
      />
      <ProfileModal />
    </>
  );
};

const MainRoomWrapper = styled.section`
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 32.0rem;
  max-width: 43.0rem;
  height: calc(var(--vh, 1vh) * 100);
  border: 0.1rem solid #ddd;
`;

const MainHeader = styled.section`
  position: relative;
  padding: 3rem 0 1.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
`;

const LogoutButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg:first-child {
    /* height: 1.686rem; */
    &:active {
      margin-left: 0.5rem;
      margin-top: 0.5rem;
    }
  }
`;

const ProfileButton = styled.div`
  width: 4rem;
  height: 4rem;

  & img:last-child {
    height: 3.8rem;
    transition-duration: 0.3s;
    &:active {
      transform: rotate(45deg);
    }
  }
`;

const MainRoomComponent = styled.div`
  position: relative;
  width: 100%;
  ${props => props.$isMain? (
    `height: calc(var(--vh, 1vh) * 100 - 19.5rem);`
  ) : (
    `height: calc(var(--vh, 1vh) * 100 - 13.9rem);`
  )};

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-image: url('/img/room_bg_logo.png');
  background-repeat: no-repeat;
  background-size: 22.9rem 26.1rem;
  background-position: center;
`;

const SendMessageWrapper = styled.form`
  position: -webkit-sticky;
  position: sticky;
  bottom: -7rem;

  transform: translateY(-100%);
  margin: 0 auto;
  padding: 0 2.2rem 0;
  width: 100%;
  min-width: 31.0rem;
  max-width: 42.0rem;
  height: 7rem;

  display: flex;
  align-items: center;
  gap: 2rem;
  color: ${(props) => props.theme.textColor};
  & input {
    padding: 0 4.7rem 0 2rem;
    width: 100%;
    height: 5.3rem;
    font-size: 12px;
    font-weight: 400;
    color: #999;
    background-color: #f1f1f1;
    border: 0rem solid #e7e6ea;
    border-radius: 1.1rem;
    outline: none;
  }
  & img:first-child {
    position: absolute;
    top: 2.2rem;
    left: 3.5rem;
    width: 2.5rem;
  }
  & img:last-child {
    position: absolute;
    top: 2.2rem;
    right: 3.5rem;
    width: 2.5rem;
    cursor: pointer;

    transition-duration: 0.3s;
    &:active {
      transform: rotate(15deg);
    }
  }
`;