import { atom } from 'recoil';

const sessionStorage = typeof window !== 'undefined' ? window.sessionStorage : undefined;
// define this function somewhere
const sessionStorageEffect = key => ({setSelf, onSet}) => {
  const savedValue = sessionStorage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue))
  }
  onSet(newValue => {
    sessionStorage.setItem(key, JSON.stringify(newValue))
  })
}

export const UrlPaths = {
  "main": "main",
  "activities": "activities",
  "personal": "personal",
}

export const isDarkAtom = atom({
  key: 'isDark',
  default: false,
});

export const profileModalInfo = atom({
  key: 'profileModal',
  default: {
    show: false,
  },
});

export const messageModalInfo = atom({
  key: 'messageModal',
  default: {
    show: false,
    pid: '',
    name: '',
    userImg: '',
  },
});

// const { persistInfoAtom } = recoilPersist({
//   key: 'joinInfoStorage',
//   storage: sessionStorage,
// });

export const joinInfo = atom({
  key: 'joinInfo',
  default: {
    workspaceName: 'localhost',
    workspaceUrl: 'localhost',
    ipAddress: '',
    nickname: '',
    userId: 0,
  },
  // effects_UNSTABLE: [persistInfoAtom],
  effects_UNSTABLE: [
    sessionStorageEffect('joinInfo'),
  ],
});

export const socketObj = atom({
  key: 'socketObj',
  default: {
    sockt: '',
  }
});